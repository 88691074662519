<template>
  <div class="patrolLine" onselectstart="return false;" >
    <div class="box">中联燃气业务数据看板</div>
    <left ref="left" />
    <right ref="right" />
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
          <subMap ref="subMap" :modeal="'1'"/>  
    </div>
  </div>
</template>
<script>
import left from './biLeft/index'
import right from './biRight/index'
// import subMap from '@/views/mapTool/subMapbi'
import subMap from '@/views/Gis/gisbi'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'PatrolHouse',
  components: { left, right ,subMap},
  props: {},
  data: () => ({
    currentArea: null,
    bubbles: null,
    chart: null,
    MenuArray:{}
  }),
  computed: {
    ...mapState('bottom', ['featureMap']),
    ...mapState('dict', ['mainAreaDict'])
  },
  watch: {
    '$store.getters.fullState'(val) {
      let rightTool = document.getElementsByClassName('rightTool')[0]
      if (val) {
        rightTool.style.right = '4.22rem'
      } else {
        rightTool.style.right = '0'
        // this.postionVal = -4.52
      }
    },
  },
  created() {},
  mounted() {
    this.MenuArray = JSON.parse(localStorage.getItem('MenuTree'))
    if(this.MenuArray){
      let exist = false
      this.MenuArray.forEach((item,index)=>{
        if(item.url == 'BI') {
          exist = true
        }
      })
      if(!exist) {
       this.$router.push("./index");
      }
    }
    window.onresize = () => {
      let state = this.$store.getters.onresize
      this.$store.commit('gis/onresizeChange', !state)
    }
    let rightTool = document.getElementsByClassName('rightTool')[0]
    rightTool.style.right = '4.22rem'
    this.getDatas()
    
  },
  methods: {
    async getDatas(){
      let _this = this
      await _this.$refs.left.getlineData()
      // await _this.$refs.left.getEquipmentData()

      // await _this.$refs.right.getTaskList()
      await _this.$refs.left.setScroll()
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  z-index: 5;
  font-size: 0.55rem;
  width: 100%;
  text-align: center;
  color: #ffffff;
  line-height: 1rem;
  font-weight: bold;
  height: 1.58rem;
  position: fixed;
  background: url('../../assets/br/bg.png') center center;
  background-size: 100% 100%;
}
.mapRight{
  width: auto;
  margin-left:0;position: relative;overflow: hidden;
  height:calc(100vh);

}
.separation {
  position: absolute;
  top: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 0.15rem;
  font-weight: 400;
  color: #74beea;
  .separation_first {
    cursor: pointer;
    width: 1.45rem;
    height: 0.32rem;
    background: #172940;
    border: 1px solid #73f7ff;
  }
  .separation_first:first-child {
    border-right: 0;
  }
  .separation_pitch {
    background: rgba(0, 246, 255, 0.16);
    color: #ffffff;
  }
}
.drop-down {
  position: absolute;
  top: 0.8rem;
  right: 0.2rem;
}
</style>
